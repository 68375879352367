import { FeatureModuleType } from '../../domain/types/featureFlagsType'
import featureFlags from '../../domain/utils/FeatureFlags'

export const modules = featureFlags.modules([])

export const initModules: (newModules: Array<FeatureModuleType>) => void = (
  newModules
) => {
  newModules.forEach((item: FeatureModuleType) => {
    modules(featureFlags.autoFillFeatureFlag)(item)
  })
}

export const getAllFeatures: () => Array<FeatureModuleType> = () =>
  modules((modules) => () => modules)('') as Array<FeatureModuleType>

export const getOnlyMicroFronts: () => Array<FeatureModuleType> = () =>
  modules(featureFlags.getFeatureByParam)(
    'frontApplication',
    true
  ) as Array<FeatureModuleType>

export const getCoreFeatureFlags: () => Array<FeatureModuleType> = () =>
  modules(featureFlags.getFeatureByParam)(
    'frontApplication',
    false
  ) as Array<FeatureModuleType>

export const isAvailableFeature: (
  moduleName: string,
  currentPath?: string
) => boolean = (moduleName, currentPath) =>
  modules(featureFlags.isAvailableFeature)(moduleName, currentPath) as boolean

export const autoFillFeatureFlag: (feature: FeatureModuleType) => void = (
  feature
) => modules(featureFlags.autoFillFeatureFlag)(feature)

export const setFeatureFlagAvailability: (
  moduleName: string,
  status: boolean
) => void = (moduleName, status) =>
  modules(featureFlags.setFeatureFlagAvailability)(moduleName, status)

export const setFeatureFlagAvailabilityByGroup: (
  moduleGroup: string,
  status: boolean
) => void = (moduleGroup, status) =>
  modules(featureFlags.setFeatureFlagAvailabilityByGroup)(moduleGroup, status)
