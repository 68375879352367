import { CssConfiguration } from './types'

const coreStylesDefinitions: Array<CssConfiguration> = [
  {
    paths: ['/svc', '/users', '/catalogue', '/dashboard'],
    styles: 'nav { height: 48px }',
  },
  {
    paths: ['/login', '/password'],
    styles: 'main { display: block !important }',
  },
]

const cssAppendByRoute: (style: string) => void = (styles) => {
  const css = document.createElement('style')
  css.id = 'custom-styles'
  css.appendChild(document.createTextNode(styles))

  if (document.getElementById('custom-styles')) {
    document.getElementById('custom-styles').remove()
  }

  document.getElementsByTagName('head')[0].appendChild(css)
}

export const cssByPathGenerator: (config: Array<CssConfiguration>) => void = (
  config = coreStylesDefinitions
) => {
  const { pathname } = window.location

  const applicableStyles: Array<CssConfiguration> = config
    .filter((item) => item.paths.some((path) => pathname.includes(path)))
    .reduce((acc, item) => {
      const foundIndex = item.id ? acc.findIndex((i) => i.id === item.id) : -1
      if (foundIndex >= 0) {
        acc[foundIndex] = item
      } else {
        acc.push(item)
      }

      return acc
    }, [])

  if (!applicableStyles.length) {
    return
  }

  const reducedStyles: string = applicableStyles
    .map((item) => item.styles)
    .join(' ')

  cssAppendByRoute(reducedStyles)
}

export const initStyleManagement: () => void = () => {
  // the popstate was used in a previous sidebar version
  window.addEventListener('popstate', () => {
    cssByPathGenerator([...coreStylesDefinitions])
  })

  cssByPathGenerator(coreStylesDefinitions)
}
