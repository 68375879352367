import { init as initApm, ApmBase } from '@elastic/apm-rum'
import { AgentOptions } from './interfaces'

const parseApmEnv = (nodeEnv: string) => {
  switch (nodeEnv) {
    case 'development':
      return 'mkp-dev-corp'
    case 'qa':
      return 'mkp-qa-corp'
    case 'production':
      return 'mkp-prod-corp'
    default:
      return 'mkp-dev-corp'
  }
}

const initOptions: AgentOptions = {
  serviceName: 'rcenter-app-base',
  serverUrl: process.env.REACT_APP_APM_URL,
  serviceVersion: '1.0.0',
  environment: parseApmEnv(process.env.NODE_ENV),
}

const startApm = (): ApmBase => initApm(initOptions)

export default startApm
