import { LOGIN_URL } from '../../domain/constants/SessionConstant'
import { removeAccessToken, getAccessToken } from '../validation'

export const saveRedirectParameterAndRemoveToken = () => {
  const currentLocation = window.location.pathname
  if (getAccessToken()) {
    removeAccessToken()
  }
  sessionStorage.setItem('lastPath', currentLocation)
  return currentLocation
}

export const redirectToLogin = (): void => {
  const path = window.location.pathname
  if (path != LOGIN_URL) {
    window.location.href = LOGIN_URL
  }
}
