import React from 'react'
import ReactDOM from 'react-dom'

import NotFound from './views/404'
import { ConfigType } from '../domain/types/configInterfaceType'

const IDS: ConfigType = {
  NOT_FOUND: {
    id: 'not-found-404',
    component: NotFound,
  },
}

const init = () => {
  Object.entries(IDS).forEach((item) => {
    const currentElement = document.getElementById(item[1].id)
    if (currentElement) {
      ReactDOM.render(React.createElement(item[1].component), currentElement)
    }
  })
}

export { IDS, init }
