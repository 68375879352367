import { handleRedirectToCorrectPath, getRefreshToken } from '../validation'

const isAvailable = handleRedirectToCorrectPath()

let tokenExpirationValidation = 0
export const redirectToLoginWhenUnauthorized = (
  saveRedirectParameter,
  redirectToLogin
): void => {
  const initTokenExpirationValidation = async () => {
    const isRefreshToken = !!getRefreshToken()
    clearTimeout(tokenExpirationValidation)
    tokenExpirationValidation = window.setTimeout(() => {
      if (!isRefreshToken && !isAvailable) {
        saveRedirectParameter()
        redirectToLogin()
      } else {
        initTokenExpirationValidation()
      }
    }, 1000)
  }

  initTokenExpirationValidation()
}
