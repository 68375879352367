import { registerApplication, start } from 'single-spa'
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout'
import { ResolvedRoutesConfig } from 'single-spa-layout/dist/types/isomorphic/constructRoutes'

import { FeatureModuleType } from './domain/types/featureFlagsType'
import { initModules, getOnlyMicroFronts } from './infrastructure/featureFlags'
import { onGetFeatureFlags } from './data/storage/featureFlagStorage'
import { init } from './presentation'
import startApm from './infrastructure/apm'
import { createBaseRoutes } from './infrastructure/layoutManagement'
import { initStyleManagement } from './infrastructure/styleManagement'
import { redirectToLoginWhenUnauthorized } from './infrastructure/authorization'
import { getAccessToken, getRefreshToken } from './infrastructure/validation'
import {
  saveRedirectParameterAndRemoveToken,
  redirectToLogin,
} from './infrastructure/redirect'

const initApplication = async () => {
  try {
    const data: FeatureModuleType[] = await onGetFeatureFlags()

    initModules(data)

    const routes: ResolvedRoutesConfig = constructRoutes({
      routes: createBaseRoutes(getOnlyMicroFronts()),
    })

    const applications = constructApplications({
      routes,
      loadApp: ({ name }) => {
        return System.import(
          getOnlyMicroFronts().find((mf) => mf.name === name).url
        )
      },
    })
    const layoutEngine = constructLayoutEngine({ routes, applications })

    applications.forEach(registerApplication)
    layoutEngine.activate()

    guardForRoutes(data)

    init()
    start()
  } catch (e) {
    console.error(e)
  }
}

function isInvalidRoute(data: FeatureModuleType[]): boolean {
  const config: {
    validRoutes: string[]
    isInvalid: boolean
  } = {
    validRoutes: [],
    isInvalid: undefined,
  }

  data.forEach((e) => {
    config.validRoutes = [...config.validRoutes, ...e.activeWhen]
  })

  config.isInvalid = config.validRoutes.every(
    (route) => window.location.pathname !== route
  )

  return config.isInvalid
}

function isRouteEqualTo(route) {
  return window.location.pathname === route
}

function isRouteWithPrefix(route) {
  return window.location.pathname.startsWith(route)
}

const guardForRoutes = (data: FeatureModuleType[]) => {
  !isInvalidRoute(data) &&
    redirectToLoginWhenUnauthorized(
      saveRedirectParameterAndRemoveToken,
      redirectToLogin
    )

  const canRedirectToHome =
    (isRouteEqualTo('/') || isRouteWithPrefix('/auth')) && getAccessToken()

  const canRedirectToLogin =
    !isRouteEqualTo('/') &&
    !isRouteWithPrefix('/auth') &&
    !getRefreshToken() &&
    !isInvalidRoute(data)

  if (canRedirectToHome) {
    window.location.href = '/home'
    return
  }

  if (canRedirectToLogin) {
    saveRedirectParameterAndRemoveToken()
    redirectToLogin()
    return
  }
}

startApm()

initApplication().then(() => {
  initStyleManagement()
})
