import { FeatureModuleType } from '../../domain/types/featureFlagsType'
import {
  ResolvedUrlRoute,
  RoutesConfig,
  UrlRoute,
} from 'single-spa-layout/dist/types/isomorphic/constructRoutes'
import { FillUrlGroup, UrlGroup } from './types'
import { IDS } from '../../presentation'
import { theme } from '@ripley-ui/core'
import { LAYOUT_ORDER } from './layoutOrder'

const createUrlRoute = (item: FeatureModuleType, activePath: string) => {
  const isRootPath: boolean = activePath === '/'

  const parsedRoute: UrlRoute = {
    type: 'route',
    path: isRootPath ? activePath : activePath.substring(1),
    exact: isRootPath,
    routes: [
      {
        type: 'application',
        name: item.name,
      },
    ],
  }

  return parsedRoute
}

const fillUrlGroup: FillUrlGroup = (
  reduceAccumulator,
  featureModule,
  activeWhenPath
) => {
  const foundIndex: number = reduceAccumulator.findIndex(
    (item) => item.type === featureModule.routeType
  )
  const foundItem: ResolvedUrlRoute = reduceAccumulator[foundIndex]

  const parsedRouteGroup: UrlGroup = {
    type: featureModule.routeType,
    routes: !!foundItem
      ? [...foundItem.routes, createUrlRoute(featureModule, activeWhenPath)]
      : [createUrlRoute(featureModule, activeWhenPath)],
  }

  return { parsedRouteGroup, foundIndex }
}

const microFrontsReducer = (
  acc: RoutesConfig[],
  featureModule: FeatureModuleType
) => {
  if (!featureModule.enabled) {
    return acc
  }

  featureModule.activeWhen.forEach((activeWhenItem) => {
    const { parsedRouteGroup, foundIndex } = fillUrlGroup(
      acc,
      featureModule,
      activeWhenItem
    )
    if (foundIndex >= 0) {
      acc[foundIndex].routes = parsedRouteGroup.routes
    } else {
      acc.push(parsedRouteGroup)
    }
  })

  return acc
}

export const createBaseRoutes: (
  microFronts: FeatureModuleType[]
) => RoutesConfig[] = (microFronts) => {
  const parsedMicroFrontends = microFronts
    .reduce(microFrontsReducer, [])
    .reduce((acc: RoutesConfig[], current: RoutesConfig) => {
      const foundIndex = LAYOUT_ORDER.findIndex((item) => item === current.type)
      acc[foundIndex] = current
      return acc
    }, [])
    .reduce((acc: RoutesConfig[], current: RoutesConfig) => {
      acc[!!acc.length ? acc.length : 0] = current
      return acc
    }, [])

  return [
    ...parsedMicroFrontends,
    {
      type: 'route',
      default: true,
      routes: [
        {
          type: 'div',
          attrs: [
            {
              name: 'id',
              value: IDS.NOT_FOUND.id,
            },
            {
              name: 'style',
              value: `background-color: ${theme.palette.grey[50]}`,
            },
          ],
        },
      ],
    } as RoutesConfig,
  ]
}
