import React from 'react'
import { useStyles } from './style'
import { RoundedButton, Container } from '@ripley-ui/core'
import Bear from '../../../../assets/images/bear.svg'

const NotFound: React.FC = () => {
  const classes = useStyles()

  const handleOnClick = () => {
    window.location.href = '/'
  }

  return (
    <Container className={classes.root} maxWidth="md">
      <div>
        <section>
          <p>Oops... algo salió mal :(</p>
          <p>En este momento no podemos cargar esta sección</p>
          <RoundedButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleOnClick}
          >
            Volver a cargar
          </RoundedButton>
          <p>
            Reportar{' '}
            <a href="http://ripleycl.attlasian.net/servicedesk/customer/portal/17">
              error
            </a>
          </p>
        </section>
      </div>
      <div>
        <img src={Bear} alt="Pet" />
      </div>
    </Container>
  )
}

export default NotFound
