import { AxiosResponse } from 'axios'
import {
  FeatureModuleType,
  FeatureResponseType,
  FeatureUrl,
} from '../../domain/types/featureFlagsType'
import { getFeatureFlags } from '../sources/featureFlagSource'
import { ORGANIZATION } from '../../domain/constants/config'
import localFeatureFlags from './feature-flags.json'
declare const window: any

const currentUrl = (url: FeatureUrl | string) => {
  if (typeof url === 'string') {
    return url
  }

  switch (process.env.NODE_ENV) {
    case 'development':
      return url.dev
    case 'qa':
      return url.qa
    case 'production':
      return url.prod
    default:
      return url.dev
  }
}

const featureResponseToModule: (
  item: FeatureResponseType,
  useSinglePageLayout?: boolean
) => FeatureModuleType = (item, useSinglePageLayout) => {
  return {
    name: item.isMicrofront ? ORGANIZATION + '/' + item.name : item.name,
    enabled: item.enabled,
    group: '',
    url: item.isMicrofront ? currentUrl(item.url) : '',
    activeWhen: item.activeWhen || [],
    frontApplication: item.isMicrofront,
    routeType: item.routeType || 'main',
  } as FeatureModuleType
}

export const featureFlagsParser: (
  featureFlags: Array<FeatureResponseType>
) => Array<FeatureModuleType> = (featureFlags) =>
  featureFlags.map((item: FeatureResponseType) =>
    featureResponseToModule(item, true)
  )

export const onGetFeatureFlags = async (): Promise<FeatureModuleType[]> => {
  try {
    const response: AxiosResponse<FeatureResponseType[]> =
      await getFeatureFlags()
    window.featureFlags = response.data
    return featureFlagsParser(response.data)
  } catch (e) {
    const data: FeatureResponseType[] = localFeatureFlags
    return featureFlagsParser(data)
  }
}
