import Cookies from 'js-cookie'
import {
  ACCESS_TOKEN,
  AVAILABLE_PATHS,
  LOGIN_URL,
  REFRESH_TOKEN,
} from '../../domain/constants/SessionConstant'

export const handleRedirectToCorrectPath = () => {
  const { pathname } = window.location
  if (pathname === '/') {
    return true
  }

  return AVAILABLE_PATHS.some((path) => window.location.pathname.includes(path))
}

export const getAccessToken = (): string => Cookies.get(ACCESS_TOKEN)

export const getRefreshToken = (): string => Cookies.get(REFRESH_TOKEN)

export const removeAccessToken = (): void => {
  Cookies.remove(ACCESS_TOKEN)
}

export const removeRefreshToken = (): void => {
  Cookies.remove(REFRESH_TOKEN)
}
