import {
  AvailableFeature,
  CallbackAction,
  CoreFeatureTypes,
  FeatureByGroupParam,
  FeatureByParam,
  Modules,
} from './types'
import { FeatureModuleType } from '../../types/featureFlagsType'

const modules: Modules =
  (customModules: Array<FeatureModuleType>) =>
  (callbackAction: CallbackAction) =>
  (params: FeatureModuleType | string, extra?: string | boolean) =>
    callbackAction(customModules)(params, extra)

const getFeatureByParam: FeatureByParam =
  (moduleItems: Array<FeatureModuleType>) =>
  (key: CoreFeatureTypes, relatedValue: string | boolean) =>
    moduleItems.filter((item) => item[key] === relatedValue)

const isAvailableFeature: AvailableFeature =
  (moduleItems: Array<FeatureModuleType>) =>
  (moduleName: string, currentPath?: string) => {
    const foundModule = moduleItems.find((item) => item.name === moduleName)

    if (!foundModule || !foundModule.enabled) {
      return false
    }

    if (currentPath && foundModule.activeWhen.length) {
      return foundModule
        ? foundModule.activeWhen.some((active) => currentPath === active)
        : false
    }
    return foundModule ? foundModule.enabled : false
  }

const autoFillFeatureFlag =
  (moduleItems: Array<FeatureModuleType>) =>
  ({
    name,
    enabled,
    group,
    activeWhen = [],
    url = '',
    frontApplication,
    routeType,
  }: FeatureModuleType) => {
    const currentIndex = moduleItems.findIndex((item) => item.name === name)

    if (currentIndex >= 0) {
      moduleItems[currentIndex].enabled = enabled
      moduleItems[currentIndex].group = group
      return
    }
    moduleItems.push({
      name,
      enabled,
      group,
      url,
      activeWhen,
      frontApplication,
      routeType,
    })
  }

const setFeatureFlagAvailability =
  (moduleItems: Array<FeatureModuleType>) =>
  ({ name, enabled }: FeatureModuleType) => {
    moduleItems.find((item) => item.name === name).enabled = enabled
  }

const setFeatureFlagAvailabilityByGroup: FeatureByGroupParam =
  (moduleItems: Array<FeatureModuleType>) =>
  (groupName: string, availableStatus: boolean) =>
    moduleItems
      .filter((item) => item.group === groupName)
      .map((item) => ({ ...item, enabled: availableStatus }))

export default {
  modules,
  getFeatureByParam,
  isAvailableFeature,
  autoFillFeatureFlag,
  setFeatureFlagAvailability,
  setFeatureFlagAvailabilityByGroup,
}
