/* eslint-disable no-useless-computed-key */
import { makeStyles } from '@material-ui/core/styles'
import { theme } from '@ripley-ui/core'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    overflow: 'visible',
    height: '100vh',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grey[900],
    ['@media (max-width: 768px)']: {
      flexFlow: 'column',
    },
    '& div:nth-child(1)': {
      width: '70%',
      alignSelf: 'center',
      '& p': {
        margin: '0px',
      },
      '& p:nth-child(1)': {
        fontSize: '3.2rem',
        fontWeight: theme.typography.fontWeightBold,
        ['@media (max-width: 768px)']: {
          fontSize: '2rem',
        },
      },
      '& p:nth-child(2)': {
        fontSize: '1.2rem',
        margin: '20px 0px',
        fontWeight: theme.typography.fontWeightRegular,
        ['@media (max-width: 768px)']: {
          fontSize: '18px',
        },
      },
      '& p:nth-child(4)': {
        fontSize: '.8rem',
        margin: '20px 0px',
        fontWeight: theme.typography.fontWeightRegular,
        '& a': {
          color: theme.palette.primary.main,
        },
      },
      ['@media (max-width: 768px)']: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      },
    },
    '& div:nth-child(2)': {
      width: '30%',
      display: 'flex',
      alignItems: 'flex-end',
      position: 'relative',
      '& img': {
        position: 'absolute',
        left: '-7rem',
      },
      ['@media (max-width: 768px)']: {
        width: '100%',
        justifyContent: 'center',
        '& img': {
          width: '25%',
          position: 'absolute',
          left: 'initial',
        },
      },
      ['@media (max-width: 425px)']: {
        '& img': {
          width: '60%',
        },
      },
    },
  },
})

export { useStyles }
