import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { getCountry } from '../../domain/utils/Session'

const configuration: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_FEATURES_API_URL,
  timeout: 5000,
}

const client: AxiosInstance = axios.create(configuration)

client.defaults.headers['Content-Type'] = 'application/json'

client.interceptors.request.use(
  (config) => config,
  (error) => {
    return Promise.reject(error)
  }
)

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    return Promise.reject(error)
  }
)

export default client
